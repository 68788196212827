'use client';

import Image from 'next/image';
import Link from 'next/link';
import styles from './header.module.css';
import { usePathname } from 'next/navigation';
import { useState, useRef, useEffect } from 'react';
import { GtagLink } from './GtagLink';
import { ReactThemeToggleButton } from '../reactThemeToggle';
import cabeceraData from '@/data/cabeceraData.json';

export const Header = () => {
	const { attributes } = cabeceraData.data.cabecera.data;
	const currentPath = usePathname();
	const [navMobileOpen, setNavMobileOpen] = useState(false);
	const firstNavLinkRef = useRef<HTMLAnchorElement>(null);
	const lastNavLinkRef = useRef<HTMLAnchorElement>(null);
	const toggleButtonRef = useRef<HTMLButtonElement>(null);
	function handleToggle() {
		setNavMobileOpen((prev) => !prev);
	}

	// Mover el enfoque al primer enlace cuando el menú se abre
	useEffect(() => {
		if (navMobileOpen && firstNavLinkRef.current) {
			firstNavLinkRef.current.focus();
		}
	}, [navMobileOpen]);

	// Manejar el cierre del menú al tabular después del último enlace
	useEffect(() => {
		const handleKeyDown = (event: globalThis.KeyboardEvent) => {
			if (!navMobileOpen) return;

			if (event.key === 'Escape') {
				setNavMobileOpen(false);
				toggleButtonRef.current?.focus();
			}

			// Trampa de enfoque para mantener el enfoque dentro del menú abierto
			if (event.key === 'Tab') {
				if (document.activeElement === lastNavLinkRef.current && !event.shiftKey) {
					event.preventDefault();
					setNavMobileOpen(false);
					toggleButtonRef.current?.focus();
				}

				if (document.activeElement === toggleButtonRef.current && event.shiftKey) {
					event.preventDefault();
					lastNavLinkRef.current?.focus();
				}
			}
		};

		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [navMobileOpen]);

	return (
		<header className={styles.header}>
			<div className={styles.headerWrapper}>
				<div className={styles.headerContentWrapper}>
					<Link href="/" className={styles.logoContainer}>
						<Image src="/imgs/logotipo.png" fill alt="Logotipo de Talleres Santa Clara." priority />
					</Link>
					<ReactThemeToggleButton />
					<button
						ref={toggleButtonRef}
						className={`${styles.navToggleButton} ${navMobileOpen ? styles.navToggleButtonOpen : ''}`}
						onClick={handleToggle}
						aria-label={navMobileOpen ? 'Cerrar menú de navegación' : 'Abrir menú de navegación'}
						aria-expanded={navMobileOpen}>
						<div className={`${styles.MobileNavIcon} ${navMobileOpen ? styles.MobileNavIconOpen : ''}`}></div>
					</button>
				</div>
				<nav
					id="primary-navigation"
					className={`${styles.headerNavigation} ${navMobileOpen ? styles.headerNavigationOpen : ''}`}
					aria-label="Menú principal">
					<ul className={styles.headerNavigationList}>
						<li className={styles.navListItem}>
							<Link
								href="/#inicio"
								className={`${currentPath === '/' ? styles.navLinkActive : ''}`}
								ref={firstNavLinkRef}>
								Inicio
							</Link>
						</li>
						<li className={styles.navListItem}>
							<Link href="/#servicios" className={`${currentPath === '/producto' ? styles.navLinkActive : ''}`}>
								Servicios
							</Link>
						</li>
						<li className={styles.navListItem}>
							<Link href="/#contacto" className={`${currentPath === '/clientes' ? styles.navLinkActive : ''}`}>
								Contacto
							</Link>
						</li>
						<li className={styles.navListItem}>
							<GtagLink>Cotiza ahora</GtagLink>
						</li>
					</ul>
				</nav>
			</div>
			<div className={styles.headerLinks}>
				<div className={styles.headerLinksContainer}>
					<ul className={styles.contact}>
						{attributes.opciones_de_contactos.data.map((opcion) => (
							<li key={opcion.attributes.spriteName}>
								<a href={opcion.attributes.href} target="_blank">
									<svg>
										<use href={`/svgs/sprite.svg#${opcion.attributes.spriteName}`}></use>
									</svg>
									<span>{opcion.attributes.texto}</span>
								</a>
							</li>
						))}
					</ul>
					<ul className={styles.headerMedia}>
						{attributes.redes.data.map((red) => (
							<li key={red.attributes.spriteName}>
								<a href={red.attributes.href} aria-label={`Enlace a ${red.attributes.nombre}`} target="_blank">
									<svg>
										<use href={`/svgs/sprite.svg#${red.attributes.spriteName}`}></use>
									</svg>
								</a>
							</li>
						))}
					</ul>
				</div>
			</div>
		</header>
	);
};

export default Header;
