'use client';

import { useState, useEffect, useRef } from 'react';
import { toast } from 'sonner';
import { sendSuscribedEmail } from './action';

function Newsletter() {
	const [email, setEmail] = useState('');
	const [loading, setLoading] = useState(false);
	const [completed, setCompleted] = useState(false);
	const input = useRef<HTMLInputElement>(null);
	const button = useRef<HTMLButtonElement>(null);

	// Utiliza useEffect para acceder a localStorage después de que el componente se haya montado
	useEffect(() => {
		const isSubscribed = window.localStorage.getItem('SUBSCRIBED');
		setCompleted(isSubscribed ? true : false);
	}, []);

	async function handleSubmit(e: React.FormEvent) {
		e.preventDefault();
		if (completed) {
			toast.warning('Ya te has suscrito recientemente.');
			setEmail('');
			if (input.current) button.current!.blur();
			return;
		}

		setLoading(true);
		const response = await sendSuscribedEmail({ email });

		if (response?.error) {
			toast.error(response?.message);
		} else {
			toast.success('Gracias por suscribirte!');
			setEmail('');
			setCompleted(true);
			window.localStorage.setItem('SUBSCRIBED', 'true');
			if (input.current) input.current.blur();
		}
		setLoading(false);
	}

	return (
		<form onSubmit={handleSubmit}>
			<h2>Newsletter</h2>
			<input
				value={email}
				onChange={(e) => setEmail(e.target.value)}
				id="newsletter-email"
				placeholder="Escribe tu correo"
				type="email"
				ref={input}
				disabled={loading}
			/>
			<button ref={button} type="submit" disabled={loading}>
				Suscribirse
			</button>
		</form>
	);
}

export default Newsletter;
