'use client';
import React, { useState } from 'react';
import { SunMoon } from 'lucide-react';
import styles from './reactThemeToggle.module.css';

export const ReactThemeToggleButton: React.FC = () => {
	// Inicia siempre con el tema claro, ignorando cualquier estado guardado
	const [isDark, setIsDark] = useState<boolean>(false);

	function handleClick(): void {
		const newTheme = !isDark;
		setIsDark(newTheme);
		document.documentElement.setAttribute('data-theme', newTheme ? 'dark' : 'light');
		// No guardamos el tema en localStorage para evitar que se recuerde entre sesiones
	}

	// Aseguramos que el tema se establezca en claro en cada carga de página
	React.useEffect(() => {
		document.documentElement.setAttribute('data-theme', 'light');
	}, []);

	return (
		<button onClick={handleClick}>
			<SunMoon className={styles.icon} width={24} height={24} />
		</button>
	);
};
