import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"weight\":[\"400\",\"600\"],\"variable\":\"--font-poppins\",\"style\":[\"normal\"],\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"poppins\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Open_Sans\",\"arguments\":[{\"weight\":[\"300\",\"400\",\"600\"],\"variable\":\"--font-openSans\",\"style\":[\"normal\"],\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"openSans\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/node_modules/sonner/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.scss");
;
import(/* webpackMode: "eager", webpackExports: ["GtagLink"] */ "/vercel/path0/src/components/fixedWhatsapp/GtagLink.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/footer/footer.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/footer/newsletter/Newsletter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/header/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ReactThemeToggleButton"] */ "/vercel/path0/src/components/reactThemeToggle/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/components/heading.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/components/fixedWhatsapp.scss");
